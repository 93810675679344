import Mock from "../mock";

const database = {
  information: {
    name: 'Seth Marcus',
    aboutContent: "Software Engineer by day, Web Dev by night. Writing 1337 code at all hours.",
    age: 27,
    employer: "Disney",
    occupation: "Software Engineer II",
    phone: '646-753-2744',
    nationality: 'American',
    language: 'English',
    hobbies: 'Cooking, Gaming, Coding, and Fantasy Football',
    email: 'seth@seth.nyc',
    address: 'NYC',
    freelanceStatus: 'Available',
    socialLinks: {
      github: 'https://github.com/methsarcus',
      facebook: 'https://facebook.com/methsarcus',
      twitter: 'https://twitter.com/methsarcus',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/methsarcus/',
      dribbble: '',
      
    },
    brandImage: '/images/brand-image.jpeg',
    aboutImage: '/images/about-image-lg.jpeg',
    aboutImageLg: '/images/about-image-lg.jpeg',
    cvfile: '/files/resume.pdf'
  },
  services: [
    {
      title: "Full Stack Web Dev",
      icon: 'code',
      details: "The most exciting development IMO, working on the web is a joy for me as long as IE compliance is not required "
    },
    {
      title: "Mobile App Dev",
      icon: 'mobile',
      details: "What I'm paid for, I have been working on Android apps using Kotlin and Java for the past 2+ years"
    },
    {
      title: "Web Design",
      icon: 'brush-alt',
      details: "With a degree in HCC I spent a decent amount of time in Figma, Illustrator, and Sketch honing my design skills."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: 'Burdette Turner',
        designation: 'Web Developer, Abc Company'
      }
    }
  ],
  skills: [
    {
      title: "TypeScript",
      value: 90
    },
    {
      title: "Kotlin",
      value: 92
    },
    {
      title: "Javascript",
      value: 75
    },
    {
      title: "Python",
      value: 80
    },

    {
      title: "Java",
      value: 65
    },
    {
      title: "Swift",
      value: 30
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Visualeague",
      subtitle: "Fantasy Football league stats visualized using Next.js and Chakra-UI. A complete rewrite from scratch of MyFantasyNumbers with more concise code and a full stack framework",
      imageUrl: "/images/visualeague-preview.png",
      largeImageUrl: ["/images/visualeague-demo.png",
      "/images/visualeague-mobile.png",
      "/images/visualeague-mobile-team-page.png",
      "/images/visualeague-custom-settings.png",
      "/images/visualeague-power-ranks.png",
      "/images/visualeague-draft.png",
      "/images/visualeague-rosters.png"],
      url: 'https://visualeague.com'
    },

    {
      id: 2,
      title: "MyFantasyNumbers",
      subtitle: "My Fantasy Football stats site with too much tech debt for refactoring. Users could enter league ID's from Sleeper or ESPN to get stats on their team and league",
      imageUrl: "/images/myfantasynumbers-preview.png",
      url: 'https://MyFantasyNumbers.com',
      largeImageUrl: [
        "/images/myfantasynumbers-large.png",
        "/images/myfantasynumbers-large-2.png"
      ]
    },
    {
      id: 3,
      title: "Good Luck Have Cheese",
      subtitle: "Barebones Starcraft cheesey strategy reporting tool, no longer live after Heroku shut down free tier",
      imageUrl: "/images/GoodLuckHaveCheeseThumbnail.png",
      largeImageUrl: [
        "/images/GoodLuckHaveCheese.png",
        "/images/GoodLuckHaveCheese.png"
      ],
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "Jan 2024 - Present",
        position: "Software Engineer II",
        company: "Disney+",
        details: "Working on Disney+ on Android and TV devices"
      },
      {
        id: 2,
        year: "2019 - 2023",
        position: "Software Engineer",
        company: "VisualDx",
        details: "After finishing my internship (and my degree), I returned to the company that gave me my first taste of real world coding and developed my Android skills"
      },
      {
        id: 3,
        year: "2016",
        position: "IT Tech",
        company: "Wealth Advisory Group",
        details: "While not a coding job I was able to work with tech helping users remove blockers from their workflow and migrating data from the legacy system they were using"
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2015 - 2020",
        graduation: "Bachelors of Science in Human Centered Computing",
        university: "Rochester Institute of Technology",
        details: "After failing to decide whether I wanted to work more on front end design or code I found out that my college had a major that would enable me to pursue both"
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Markdown & Html supported blog.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    }
  ],
  contactInfo: {
    phoneNumbers: ['+1-646-753-2744'],
    emailAddress: ['seth@seth.nyc'],
    address: "243 Riverside Drive, NY NY 10025"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});